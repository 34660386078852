import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, enableIndexedDbPersistence } from "firebase/firestore";

console.log("process.env.NODE_ENV", process.env.NODE_ENV);

let firebaseConfig = {
    apiKey: "AIzaSyDPPET6HZGessZ9vJW0KDw9njZnCuAyLMA",
    authDomain: "plc-auth-prod.firebaseapp.com",
    projectId: "plc-auth-prod",
    storageBucket: "plc-auth-prod.appspot.com",
    messagingSenderId: "622505705272",
    appId: "1:622505705272:web:4d3f52cd0401e65e408bcd",
};

if (["development", "localhost"].includes(process.env.NODE_ENV || "")) {
    firebaseConfig = {
        apiKey: "AIzaSyDkwfcU1rKPs_-HPjtENBIpqxJDYhDyQC0",
        authDomain: "plc-auth.firebaseapp.com",
        projectId: "plc-auth",
        storageBucket: "plc-auth.appspot.com",
        messagingSenderId: "376617146769",
        appId: "1:376617146769:web:bc2afb859a44bd97782230",
    };
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

enableIndexedDbPersistence(firestore).catch((err) => {
    if (err.code == "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
    } else if (err.code == "unimplemented") {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
    }
});
// Subs

export { auth, firestore };
