import {
    Clock,
    Package,
    PieChart,
    Search,
    TrendingUp,
    XOctagon,
    Delete,
    StopCircle,
    Tool,
    UserCheck,
    CheckCircle,
    Zap,
} from "react-feather";

const asideMenus = [
    {
        id: 1,
        label: "Strategy",
        url: "/",
        Icon: PieChart,
        submenu: [
            {
                id: 11,
                label: "ALL Opportunity",
                url: "/",
                Icon: Search,
            },
            {
                id: 9,
                label: "Filtered Opportunity",
                url: "/filtered-opportunity",
                Icon: Search,
            },
        ],
    },
    {
        id: 2,
        label: "Delta Neutral Executions",
        url: "/",
        Icon: Package,
        submenu: [
            {
                id: 13,
                label: "Opening",
                url: "/funding-rate/executions-open",
                Icon: TrendingUp,
            },
            {
                id: 12,
                label: "Failed",
                url: "/funding-rate/executions-fail",
                Icon: Delete,
            },
            {
                id: 12,
                label: "Closed",
                url: "/funding-rate/executions-close",
                Icon: Clock,
            },
            {
                id: 12,
                label: "Error",
                url: "/funding-rate/executions-error",
                Icon: XOctagon,
            },
        ],
    },
    {
        id: 2,
        label: "Risk Management",
        url: "/",
        Icon: Package,
        submenu: [
            {
                id: 25,
                label: "Balance",
                url: "/funding-rate/real-balance",
                Icon: CheckCircle,
            },
            {
                id: 25,
                label: "Leverage",
                url: "/funding-rate/balance",
                Icon: Package,
            },
            {
                id: 26,
                label: "Positions",
                url: "/funding-rate/positions",
                Icon: StopCircle,
            },
            {
                id: 27,
                label: "Balance trades vs service",
                url: "/balances-trades-vs-service",
                Icon: Tool,
            },
            {
                id: 28,
                label: "Hedge Positions",
                url: "/hedge-positions",
                Icon: UserCheck,
            },
        ],
    },
    {
        id: 2,
        label: "Bot Management",
        url: "/",
        submenu: [
            {
                id: 25,
                label: "Running Bots",
                url: "/running-bots",
                Icon: Zap,
            },
        ],
    },
];

export default asideMenus;
