import { useState, useEffect } from "react";
import { auth } from "../config/firebase";

const useToken = () => {
    const [token, setToken] = useState("");
    useEffect(() => {
        return auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken(true)
                    .then((latestToken) => {
                        setToken(latestToken);
                    })
                    .catch((err) => console.log(err));
            } else {
                if (location.pathname != "/secure/signin") {
                    location.href = location.origin + "/secure/signin";
                }
            }
        });
    }, []);
    return token;
};

const getUID = () => {
    const [token, setToken] = useState("");
    useEffect(() => {
        return auth.onAuthStateChanged((user) => {
            if (user) {
                console.log("user", user.uid);
                setToken(user.uid);
            } else {
                if (location.pathname != "/secure/signin") {
                    location.href = location.origin + "/secure/signin";
                }
            }
        });
    }, []);
    return token;
};

export { useToken, getUID };
